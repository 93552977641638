import React, { useContext } from 'react'
import { ItemModalContext } from 'scripts/store'
import { ActionType } from 'scripts/actions/actions'

export interface FormatSelectorOption {
  label: string
  active: boolean
  format: string
}
const Option: React.FC<FormatSelectorOption> = ({ label, active, format }) => {
  const { dispatch } = useContext(ItemModalContext)

  return (
    <div
      className={active ? 'select-box__option is-active' : 'select-box__option'}
      onClick={() => {
        dispatch({ type: ActionType.SET_ACTIVE_DOWNLOAD_FORMAT, format })
      }}>
      <span>{label}</span>
    </div>
  )
}
export default Option
