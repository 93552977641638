import React from 'react'

export interface ActionButton {
  label: string
  settings: { [key: string]: any }
  onButtonClick: (param?: any) => void
}

const ActionButton: React.FC<ActionButton> = ({ label, settings, onButtonClick }) => {
  return (
    <button {...settings} onClick={() => onButtonClick()}>
      {label}
    </button>
  )
}
export default ActionButton
