import React, { KeyboardEvent, SyntheticEvent, useContext, useState } from 'react'
import Svg from 'react-inlinesvg'
import SearchItem from './sub'
import { AppContext } from 'scripts/store'
import { ActionType } from 'scripts/actions/actions'

const SearchField: React.FC = () => {
  const { searchField, dispatch } = useContext(AppContext)

  const [borderedSearchField, setBorderedSearchField] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [placeholder, setPlaceholder] = useState('Search for Objects ...')
  const [inputWidth, setInputWidth] = useState({ width: 'auto' })

  const inputFieldFactor = 11

  function removeItem(item: string) {
    dispatch({ type: ActionType.DELETE_ITEM_IN_SEARCH_FIELD, item })
  }

  function onFocus() {
    setPlaceholder(''.trim())
    setBorderedSearchField(true)
  }

  function onBlur() {
    setPlaceholder('Search for Objects ...')
    setInputWidth({ width: 'auto' })
    setInputValue(''.trim())
    setBorderedSearchField(false)
  }

  function onResetClick() {
    dispatch({ type: ActionType.RESET_SEARCH_FIELD })
    setInputValue('')
  }

  function inputOnChange(event: SyntheticEvent<HTMLInputElement>) {
    const length = event.currentTarget.value.length
    setInputValue(event.currentTarget.value.trim())
    setInputWidth({ width: `${3 + length * inputFieldFactor}px` })
  }

  function inputOnKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    // Add a searchstring to the input field
    if (event.key === ' ' || event.key === 'Enter') {
      const searchQuery = searchField
      if (inputValue.trim() !== '' && searchQuery.indexOf(inputValue) < 0) {
        // Give current InputValue as searchQuery into function
        setInputValue('')
        dispatch({ type: ActionType.SET_SEARCH_FIELD, item: inputValue })
      }
    }

    // Remove last tag in search field by hitting backspace
    if (event.key === 'Backspace') {
      if (inputValue.length <= 1) {
        const itemToRemove = searchField.pop()
        if (itemToRemove && itemToRemove.length > 0) {
          removeItem(itemToRemove)
          setInputValue(itemToRemove)
        }
      }
    }

    // Preventing to send form and reload page by hitting enter
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const searchItem: React.ReactNodeArray = []

  if (searchField.length > 0) {
    searchField.forEach((item: string, key: number) => {
      searchItem.push(<SearchItem item={item} type="tag" key={`searchitem-${key}`} removeItem={removeItem} />)
    })
  }

  const iconClose =
    searchField.length > 0 ? (
      <span onClick={onResetClick}>
        <Svg src="/svgs/close.svg" />
      </span>
    ) : null
  const iconSearch = <Svg src="/svgs/zoom.svg" description="Zoom svg for searching" />
  const searchIconClass = searchField.length > 0 ? 'search-bar__zoom is_active' : 'search-bar__zoom'

  return (
    <div className="search-bar-container">
      <div className="search-bar" data-border={borderedSearchField && 'bordered'}>
        <form method="POST" action="" id="search-tags">
          <span className={searchIconClass}>{iconSearch}</span>
          <div className="hidden_search">{searchItem}</div>
          <input
            type="text"
            name="tag_search"
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={inputOnKeyPress}
            onChange={inputOnChange}
            value={inputValue}
            placeholder={placeholder}
            style={inputWidth}
          />
          <span className="search-bar__reset">{iconClose}</span>
        </form>
      </div>
    </div>
  )
}

export default SearchField
