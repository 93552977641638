const isBrowser = typeof window !== 'undefined'
const Packery = isBrowser ? window.Packery || require('packery') : null
const Draggabilly = isBrowser ? window.Draggabilly || require('draggabilly') : null
const imagesloaded = isBrowser ? require('imagesloaded') : null
const assign = require('lodash.assign')
const debounce = require('lodash.debounce')
const omit = require('lodash.omit')
const React = require('react')
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const refName = 'packeryContainer'

const propTypes = {
  disableImagesLoaded: PropTypes.bool,
  options: PropTypes.object,
  enableDragging: PropTypes.bool,
  updateOnEachImageLoad: PropTypes.bool,
  onImagesLoaded: PropTypes.func,
  elementType: PropTypes.string,
}

const PackeryComponent = createReactClass({
  packery: false,
  domChildren: [],
  displayName: 'PackeryComponent',
  propTypes: propTypes,
  canRefresh: true,

  getDefaultProps() {
    return {
      disableImagesLoaded: false,
      options: {},
      className: '',
      elementType: 'div',
      enableDragging: false,
      updateOnEachImageLoad: false,
    }
  },

  makeDraggable: function(itemElem) {
    // make element draggable with Draggabilly
    const draggie = new Draggabilly(itemElem)
    // bind Draggabilly events to Packery
    this.packery.bindDraggabillyEvents(draggie)
  },

  initializePackery: function(force) {
    if (!this.packery || force) {
      this.packery = new Packery(this.refs[refName], this.props.options)

      if (this.props.enableDragging) {
        this.packery.getItemElements().forEach(this.makeDraggable)
      }
      this.domChildren = this.getNewDomChildren()
    }
  },

  getNewDomChildren() {
    const node = this.refs[refName]
    const children = this.props.options.itemSelector
      ? node.querySelectorAll(this.props.options.itemSelector)
      : node.children
    return Array.prototype.slice.call(children)
  },

  diffDomChildren() {
    const oldChildren = this.domChildren.filter(function(element) {
      /*
       * take only elements attached to DOM
       * (aka the parent is the packery container, not null)
       */
      return !!element.parentNode
    })

    const newChildren = this.getNewDomChildren()

    const removed = oldChildren.filter(function(oldChild) {
      return !~newChildren.indexOf(oldChild)
    })

    const domDiff = newChildren.filter(function(newChild) {
      return !~oldChildren.indexOf(newChild)
    })

    let beginningIndex = 0

    // get everything added to the beginning of the DOMNode list
    const prepended = domDiff.filter(function(newChild) {
      const prepend = beginningIndex === newChildren.indexOf(newChild)

      if (prepend) {
        // increase the index
        beginningIndex++
      }

      return prepend
    })

    // we assume that everything else is appended
    const appended = domDiff.filter(function(el) {
      return prepended.indexOf(el) === -1
    })

    /*
     * otherwise we reverse it because so we're going through the list picking off the items that
     * have been added at the end of the list. this complex logic is preserved in case it needs to be
     * invoked
     *
     * var endingIndex = newChildren.length - 1;
     *
     * domDiff.reverse().filter(function(newChild, i){
     *     var append = endingIndex == newChildren.indexOf(newChild);
     *
     *     if (append) {
     *         endingIndex--;
     *     }
     *
     *     return append;
     * });
     */

    // get everything added to the end of the DOMNode list
    let moved = []

    if (removed.length === 0) {
      moved = oldChildren.filter(function(child, index) {
        return index !== newChildren.indexOf(child)
      })
    }

    this.domChildren = newChildren

    return {
      old: oldChildren,
      new: newChildren,
      removed: removed,
      appended: appended,
      prepended: prepended,
      moved: moved,
    }
  },

  performLayout() {
    const diff = this.diffDomChildren()

    if (diff.removed.length > 0) {
      this.packery.remove(diff.removed)
    }

    if (diff.appended.length > 0) {
      this.packery.appended(diff.appended)
    }

    if (diff.prepended.length > 0) {
      this.packery.prepended(diff.prepended)
    }

    this.packery.reloadItems()

    if (this.props.enableDragging) {
      this.packery.getItemElements().forEach(this.makeDraggable)
    }

    this.packery.layout()
  },

  imagesLoaded() {
    if (this.props.disableImagesLoaded) return

    imagesloaded(this.refs[refName]).on(
      this.props.updateOnEachImageLoad ? 'progress' : 'always',
      debounce(
        function(instance) {
          if (this.props.onImagesLoaded) {
            this.props.onImagesLoaded(instance)
          }
          this.packery.layout()
        }.bind(this),
        100,
      ),
    )
  },

  componentDidMount() {
    this.initializePackery()
    this.imagesLoaded()
  },

  componentDidUpdate() {
    this.performLayout()
    this.imagesLoaded()
  },

  componentWillReceiveProps() {
    this._timer = setTimeout(
      function() {
        if (!this.canRefresh) return

        this.packery.reloadItems()
        this.forceUpdate()
      }.bind(this),
      1000,
    )
  },

  componentWillUnmount() {
    clearTimeout(this._timer)
    this.canRefresh = false
    this.packery.destroy()
  },

  render() {
    const props = omit(this.props, Object.keys(propTypes))
    return React.createElement(this.props.elementType, assign({}, props, { ref: refName }))
  },
})

export default PackeryComponent
