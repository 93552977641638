import React from 'react'

const NewItemIndicator: React.FC<{ className: string; text?: string }> = ({ className, text }) => {
  return (
    <div className={className}>
      <span>{text}</span>
    </div>
  )
}

export default NewItemIndicator
