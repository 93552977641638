import getCached from './cache'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'scripts/store'

type Response = [{ [key: string]: any }] | []

export function useGetCategories(): [Response, boolean] {
  const [loading, setLoading] = useState<boolean>(true)
  const [response, setResponse] = useState<Response>([])

  const { tenant, envUrl } = useContext(AppContext)

  useEffect(() => {
    getCached(`${envUrl}/getcategories/?index&tenant=${tenant}`).then(r => {
      setResponse(r.data)
      setLoading(false)
    })
  }, []) // eslint-disable-line

  return [response, loading]
}
