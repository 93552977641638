import React, { ReactNode } from 'react'
import { useGetActiveSvg } from 'scripts/api/svg-api'
import Loading from '../Loading'
import Svg from 'react-inlinesvg'
import ItemContent from './sub/itemContent'

const ItemModal: React.FC<{ id: number; resetModal: () => void }> = ({ id, resetModal }) => {
  const [item, loading] = useGetActiveSvg(id)

  function onClickClose() {
    resetModal()
  }

  let itemContent: ReactNode
  let loadingModal: ReactNode

  if (!loading && item) {
    itemContent = <ItemContent loading={loading} item={item} />
  } else {
    loadingModal = <Loading text="loading" />
  }
  return (
    <div className="svgitem-modal">
      {loadingModal}
      <span className="svgitem-modal__close">
        <Svg src="/svgs/close.svg" onClick={onClickClose} />
      </span>
      {itemContent}
    </div>
  )
}
export default ItemModal
