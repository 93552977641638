import { createContext } from 'react'
import { AppConfig } from './types/appcontext-store'
import { ItemModalConfig } from './types/item-modal-store'
import { itemModalState } from './item-modal-reducer'
import { appContextState } from './appcontext-reducer'

const AppContext: React.Context<AppConfig> = createContext(appContextState)
const ItemModalContext: React.Context<ItemModalConfig> = createContext(itemModalState)

export { AppContext, ItemModalContext }
