import React from 'react'

const Title: React.FC<{ title: string; categories: any }> = ({ title, categories }) => {
  let categoryText: string[] = []
  if (categories.length > 0) {
    categories.forEach((element: { [key: string]: any }) => {
      categoryText.push(element.category_name)
    })
  }

  return (
    <div className="svgitem-modal__title">
      {title}
      <div className="svgitem-modal__sub-title">{categoryText.join(', ')}</div>
    </div>
  )
}
export default Title
