import './scripts/utils/object-assign-polyfill'
import 'promise-polyfill/src/polyfill';
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/css/index.css'
import App from './scripts/react/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
