import axios, { AxiosResponse, CancelToken } from 'axios'

export default function getCached(url: string, token?: CancelToken): Promise<any> {
  return new Promise((resolve: any, reject: any) => {
    const result = axios.get(url, { cancelToken: token })
    result.then((response: AxiosResponse<any>) => {
      resolve(response)
    })
  })
}
