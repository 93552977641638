import React, { useReducer } from 'react'
import ReactDom from 'react-dom'
import ItemModal from '../../ItemModal'
import Overlay from '../../Overlay'
import { ItemModalContext } from 'scripts/store'
import itemModalReducer, { itemModalState } from 'scripts/item-modal-reducer'

interface ModalPortalProps {
  itemID: number
  resetModal: () => void
}

const ModalPortal: React.FC<ModalPortalProps> = ({ itemID, resetModal }) => {
  const [itemModalStore, ItemModalDispatch] = useReducer(itemModalReducer, itemModalState)

  return ReactDom.createPortal(
    <ItemModalContext.Provider value={{ ...itemModalStore, dispatch: ItemModalDispatch }}>
      <Overlay resetModal={resetModal} />
      <ItemModal resetModal={resetModal} id={itemID} />
    </ItemModalContext.Provider>,
    // @ts-ignore
    document.getElementById('item-modal'),
  )
}
export default ModalPortal
