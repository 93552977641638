import React from 'react'

export interface LoadingModule {
  className?: string
  text?: string
}

const Loading: React.FC<LoadingModule> = ({ className, text }) => {
  return (
    <div className={className}>
      <div className="loading-container">
        <span>{text}</span>
        <div id="lh-loading">
          <div id="lh-loading_1" className="lh-loading"></div>
          <div id="lh-loading_2" className="lh-loading"></div>
          <div id="lh-loading_3" className="lh-loading"></div>
          <div id="lh-loading_4" className="lh-loading"></div>
          <div id="lh-loading_5" className="lh-loading"></div>
          <div id="lh-loading_6" className="lh-loading"></div>
          <div id="lh-loading_7" className="lh-loading"></div>
          <div id="lh-loading_8" className="lh-loading"></div>
          <div id="lh-loading_9" className="lh-loading"></div>
        </div>
      </div>
    </div>
  )
}
export default Loading
