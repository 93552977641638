import { AppConfig, SearchField } from './types/appcontext-store'
import { Action, ActionType } from './actions/actions'

export const appContextState: AppConfig = {
  tenant: 'Q0PtRH81e6sa8uh',
  envUrl: '',
  downloadUrl: '',
  searchField: [],
  dispatch: () => ({}),
  isLoggedIn: false,
  libraryLoginName: 'libraryLogin',
  libraryLoginValue: '1',
}

function inIframe () {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

//#################################
// GET REFERRER FOR LOGIN
// If referrer is frontify autoLogin
//const referrerCheck = 'https://frontify.lufthansa.com'
if ( inIframe()
  || localStorage.getItem(appContextState.libraryLoginName) === appContextState.libraryLoginValue) {
  Object.assign(appContextState, { ...appContextState, isLoggedIn: true })
}
//#################################
// GET CORRECT ENVIRONMENT
const host = window.location.host
if (host === 'localhost:3000' || host === 'localhost:4000' || host === 'objectdb.lcl') {
  const envUrl = 'https://libraries.lcl'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
} else if (host === 'object-library.debd.com' || host === 'lufthansa-objects.debd.com') {
  const envUrl = 'https://liberries.debd.com'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
} else {
  const envUrl = 'https://liberries.debd.com'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
}
//#################################

/***********************************
 * REDUCER
 ***********************************/

const reducer = (state: AppConfig = appContextState, action: Action): AppConfig => {
  switch (action.type) {
    case ActionType.SET_SEARCH_FIELD: {
      return {
        ...state,
        searchField: [...state.searchField, action.item],
      }
    }
    case ActionType.RESET_SEARCH_FIELD_AND_ADD_CATEGORY: {
      return {
        ...state,
        searchField: [action.item],
      }
    }
    case ActionType.RESET_SEARCH_FIELD: {
      return {
        ...state,
        searchField: [],
      }
    }
    case ActionType.DELETE_ITEM_IN_SEARCH_FIELD: {
      const clonedSearchField: SearchField = [...state.searchField]
      const filteredSearchField = clonedSearchField.filter((element, key) => {
        return element !== action.item
      })
      return {
        ...state,
        searchField: filteredSearchField,
      }
    }
    case ActionType.SET_LOGIN: {
      return {
        ...state,
        isLoggedIn: action.login,
      }
    }
    default: {
      return { ...state }
    }
  }
}
export default reducer
