import React, { ReactNodeArray } from 'react'
import Tagitem from './sub'

export interface Tag {
  tag_name: string
  tag_id: number
}

const Tags: React.FC<{ tags: Tag[] }> = ({ tags }) => {
  const bubbles: ReactNodeArray = []

  if (tags.length > 0) {
    tags.forEach((k, v) => {
      bubbles.push(<Tagitem text={k.tag_name} key={`tag-${k.tag_id}`} />)
    })
  }

  return <div className="svgitem-modal__bubbles">{bubbles}</div>
}
export default Tags
