import { Action, ActionType } from './actions/actions'
import { ItemModalConfig } from './types/item-modal-store'

export const itemModalState: ItemModalConfig = {
  downloadFormats: [{ label: 'PNG', format: 'png' }, { label: 'SVG', format: 'svg' }, { label: 'PDF', format: 'pdf' }],
  activeDownloadFormat: 'png',
  dispatch: () => ({}),
}

/***********************************
 * REDUCER
 ***********************************/

const reducer = (state: ItemModalConfig = itemModalState, action: Action): ItemModalConfig => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_DOWNLOAD_FORMAT: {
      return {
        ...state,
        activeDownloadFormat: action.format,
      }
    }
    default: {
      return { ...state }
    }
  }
}
export default reducer
