const errorTitles: { [key: string]: string } = {
  WRONG_CREDENTIALS: 'Password or username is wrong',
  USERNAME: 'Username should not be empty',
  PASSWORD: 'Password should not be empty',
}

function translateError(errorKey: string) {
  return errorTitles[errorKey]
}

export default translateError
