import React, { Dispatch, SetStateAction, SyntheticEvent, useRef } from 'react'
import ActionButton from '../ActionButton'
import Loading from '../Loading'
import Error from '../Error'
import Svg from 'react-inlinesvg'

interface Login {
  setUsername: Dispatch<SetStateAction<string>>
  setPassword: Dispatch<SetStateAction<string>>
  setError: Dispatch<SetStateAction<String[]>>
  error: String[]
  loading: boolean
}

const Login: React.FC<Login> = ({ setUsername, setPassword, error, setError, loading }) => {
  const usernameRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  function handleOnSubmit(event: SyntheticEvent) {
    let errors: String[] = []
    setError([])

    if (usernameRef.current && usernameRef.current.value.trim().length === 0) {
      errors.push('USERNAME')
    }

    if (passwordRef.current && passwordRef.current.value.trim().length === 0) {
      errors.push('PASSWORD')
    }

    if (
      usernameRef.current &&
      usernameRef.current.value.length > 0 &&
      passwordRef.current &&
      passwordRef.current.value.length > 0 &&
      errors.length === 0
    ) {
      setUsername(usernameRef.current.value)
      setPassword(passwordRef.current.value)
    } else {
      setError(errors)
    }
    event.preventDefault()
  }

  return (
    <div className="login">
      <div className="login__head">
        <Svg src="/svgs/liberries-logo.svg" />
      </div>
      <div className="login__form form">
        <div className="login__title">Login</div>
        <form onSubmit={handleOnSubmit}>
          {Object.keys(error).length > 0 && <Error error={error} />}
          <div className="form__row">
            <div className="form__field">
              <label>User</label>
              <input ref={usernameRef} type="text" name="username" placeholder="Username or E-Mail" />
            </div>
          </div>
          <div className="form__row">
            <div className="form__field">
              <label>Password</label>
              <input ref={passwordRef} type="password" name="password" placeholder="Password" autoComplete="off" />
            </div>
          </div>
          <div className="form__row">
            <div className="form__field">
              {loading ? (
                <Loading className="login__loading" />
              ) : (
                <ActionButton
                  label="Login"
                  settings={{ name: 'loginButton', className: 'button button--success' }}
                  onButtonClick={() => null}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Login
