import React from 'react'
import Item from 'scripts/interface/Item.interface'
import NewItemIndicator from '../NewItemIndicator'
import getItemDimensionStyle from 'scripts/utils/set-item-dimension'

const ListItem: React.FC<{ item: Item; handleModal: (...args: any[]) => void }> = ({ item, handleModal }) => {
  const svgIsNew = item.is_new ? <NewItemIndicator className="svg-grid__new" /> : ''
  const svgGridClass =
    item.dimensions.width > item.dimensions.height ? 'svg-grid__item svg-grid__item--2' : 'svg-grid__item'

  function onClickHandle() {
    handleModal(item.ID)
  }

  const dimensionStyle = getItemDimensionStyle(item.dimensions.width, item.dimensions.height, item.preview_width)
  return (
    <div className={svgGridClass} style={dimensionStyle} onClick={onClickHandle}>
      <div className="svg-grid__item--triangle"></div>
      <div className="svg-grid__image">
        {svgIsNew}
        <div className="svg-grid__item--image" dangerouslySetInnerHTML={{ __html: JSON.parse(item.xml) }}></div>
      </div>
    </div>
  )
}

export default ListItem
