export default function getItemDimensionStyle(width: number, height: number, preview_width: number = 0) {
  let style: any = {}

  // All items divided by 10
  width = width / 10
  height = height / 10

  let newWidth: any = width

  if (preview_width > 0) {
    newWidth = `${preview_width}px`
  } else if (width >= 1000 && height >= 1000) {
    newWidth = '300px'
  } else if (width >= 1000) {
    newWidth = '400px'
  } else if (width >= 250 && height >= 700) {
    newWidth = '130px'
  } else if (width >= 600 && height >= 400) {
    newWidth = '200px'
  } else if (width >= 600) {
    newWidth = '300px'
  } else if (width >= 200) {
    newWidth = '200px'
  } else if (width >= 150) {
    newWidth = '150px'
  } else if (width <= 10 && height <= 10) {
    newWidth = '35px'
  } else if (width <= 5) {
    newWidth = '20px'
  } else if (width <= 8 && height >= 100) {
    newWidth = '10px'
  } else if (width <= 8) {
    newWidth = '20px'
  } else if (width <= 50 && height > 100) {
    newWidth = '35px'
  } else if (width <= 50) {
    newWidth = '50px'
  } else {
    newWidth = `${Math.floor(width)}px`
  }

  style = {
    width: newWidth,
    boxSizing: 'content-box',
  }
  return style
}
