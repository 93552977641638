import React, { ReactNodeArray, useContext } from 'react'
import Option from './sub'
import { ItemModalContext } from 'scripts/store'

const FormatSelector: React.FC = () => {
  const { activeDownloadFormat, downloadFormats } = useContext(ItemModalContext)
  let options: ReactNodeArray = []

  downloadFormats.forEach((item: { [key: string]: any }, key: number) => {
    options.push(
      <Option
        label={item.label}
        format={item.format}
        active={item.format === activeDownloadFormat}
        key={`format-select-${key}`}
      />,
    )
  })

  return (
    <div>
      <div className="svgitem-modal__selectbox-border-bottom"></div>
      <div className="select-box">{options}</div>
    </div>
  )
}
export default FormatSelector
