import React from 'react'
import Svg from 'react-inlinesvg'

const SearchItem: React.FC<{ item: string; type: string; removeItem: (item: any) => void }> = ({
  item,
  type,
  removeItem,
}) => {
  function onRemoveClick(): void {
    removeItem(item)
  }

  const itemClass = `search-bar__item search-bar__item--${type}`

  return (
    <div className={itemClass}>
      {item}
      <span onClick={() => onRemoveClick()}>
        <Svg src="/svgs/close.svg" />
      </span>
    </div>
  )
}
export default SearchItem
