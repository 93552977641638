import React, { ReactNodeArray } from 'react'
import ErrorItem from './sub/item'
import translateError from 'scripts/utils/error-strings'

const Error: React.FC<{ error: { [key: string]: any } }> = ({ error }) => {
  const ErrorItems: ReactNodeArray = []

  error.forEach((element: string, key: number) => {
    ErrorItems.push(<ErrorItem key={`erroritem-${key}`} text={translateError(element)} />)
  })
  return (
    <div className="login__error">
      <span>Something went wrong:</span>
      {ErrorItems}
    </div>
  )
}
export default Error
