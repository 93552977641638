import React, { useState } from 'react'

import { AppContext } from 'scripts/store'
import H2 from '../Headline/h2'
import Paragraph from '../Paragraph'
import { useGetSvgs } from 'scripts/api/svg-api'
import CategoryReel from '../CategoryReel'
import ItemGrid from './sub/item-grid'
import ModalPortal from './sub/item-modal-portal'

const ItemList: React.FC = () => {
  const { searchField } = React.useContext(AppContext)
  const [sortBy, setSortBy] = useState<string>('random')
  const [selectedItem, setSelectedItem] = useState<number>(0)

  function handleChangeSortBy(sorting: string): void {
    setSortBy(sorting)
  }
  // Reset Item Modal
  function resetModal() {
    setSelectedItem(0)
  }

  const [items, loading] = useGetSvgs(searchField, sortBy)

  const sortByName: string = sortBy === 'archive' ? 'sort-by active' : 'sort-by'
  const sortByDate: string = sortBy === 'created_at' ? 'sort-by active' : 'sort-by'
  const sortByRandom: string = sortBy === 'random' ? 'sort-by active' : 'sort-by'

  let listItems = Object.keys(items)
  let countItems: number = listItems.length

  const objectCounterTitle: string = 'Objects found'
  const headline: string = 'Browse all'
  const objectCounter: string = loading ? `Loading objects...` : `${countItems} ${objectCounterTitle}`

  return (
    <>
      {selectedItem > 0 && <ModalPortal itemID={selectedItem} resetModal={resetModal} />}
      <div className="svg-meta">
        <CategoryReel />
        <div className="svg-meta">
          <div className="svg-meta__side">
            <H2 text={headline} />
            <Paragraph text={objectCounter} />
          </div>
          <div className="svg-meta__side">
            Sort by
            <span
              className={sortByRandom}
              onClick={() => {
                handleChangeSortBy('random')
              }}>
              Random
            </span>
            |
            <span
              className={sortByDate}
              onClick={() => {
                handleChangeSortBy('created_at')
              }}>
              Date
            </span>
            |
            <span
              className={sortByName}
              onClick={() => {
                handleChangeSortBy('archive')
              }}>
              Name
            </span>
          </div>
        </div>
        <div className="svg-grid">
          <ItemGrid
            loading={loading}
            countItems={countItems}
            searchField={searchField}
            listItems={listItems}
            items={items}
            setSelectedItem={setSelectedItem}
          />
        </div>
      </div>
    </>
  )
}

export default ItemList
