import React from 'react'

export interface Paragraph {
  text: string
  settings?: {
    [key: string]: string | number
  }
}

const Paragraph: React.FC<Paragraph> = ({ text, settings }) => {
  return <p {...settings}>{text}</p>
}

export default Paragraph
