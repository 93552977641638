import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'scripts/store'
import SearchField from '../SearchField'
import ItemList from '../ItemList'
import Login from '../Login'

import getCached from 'scripts/api/cache'
import base64 from 'base-64'
import { ActionType } from 'scripts/actions/actions'

const Wrapper: React.FC = () => {
  const [error, setError] = useState<String[]>([])
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const { envUrl, tenant, dispatch, isLoggedIn, libraryLoginName, libraryLoginValue } = useContext(AppContext)

  useEffect(() => {
    if (username.length > 0 && password.length > 0 && error.length === 0 && !isLoggedIn) {
      setLoading(true)
      getCached(
        `${envUrl}/login/?index&tenant=${tenant}&username=${username}&password=${base64.encode(password)}`,
      ).then(response => {
        if (response.data === 'error') {
          setError(['WRONG_CREDENTIALS'])
        } else {
          setError([])
          localStorage.setItem(libraryLoginName, libraryLoginValue)
          dispatch({ type: ActionType.SET_LOGIN, login: true })
        }
        setLoading(false)
      })
    }
  }, [error, username, password, isLoggedIn]) // eslint-disable-line

  return (
    <>
      {isLoggedIn ? (
        <>
          <SearchField />
          {/* Portal Root for Modal and Overlay */}
          <div id="item-modal"></div>
          <div className="svg">
            <ItemList />
          </div>
        </>
      ) : (
        <Login
          setUsername={setUsername}
          setPassword={setPassword}
          setError={setError}
          error={error}
          loading={loading}
        />
      )}
    </>
  )
}
export default Wrapper
