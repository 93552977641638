import React from 'react'

const NoItemsFound: React.FC = () => {
    return (
        <div className="svg-grid__nothing-found">
            <h2>Sorry, no items founds.</h2>
            <p>Please try another keyword.</p>
        </div>
    )
}
export default NoItemsFound