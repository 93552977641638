import React, { useReducer } from 'react'

import appReducer, { appContextState } from 'scripts/appcontext-reducer'
import { AppContext } from 'scripts/store'
import Wrapper from './components/Wrapper'

const App: React.FC = () => {
  const [appStore, appDispatch] = useReducer(appReducer, appContextState)

  return (
    <AppContext.Provider value={{ ...appStore, dispatch: appDispatch }}>
      <div className="wrap">
        <div className="container">
          <Wrapper />
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default App
