import React, { ReactNodeArray, useRef } from 'react'
import SVG from 'react-inlinesvg'
import Flickity, { FlickityOptions } from 'react-flickity-component'

const FlickityLayout: React.FC<{ flickityOptions: FlickityOptions; items: ReactNodeArray }> = ({
  flickityOptions,
  items,
}) => {
  const flickity = useRef<HTMLDivElement>(null)

  function onFlickityNextClick() {
    if (flickity && flickity.current) {
      // @ts-ignore
      flickity.current.flkty.next()
    }
  }

  function onFlickityPrevClick() {
    if (flickity && flickity.current) {
      // @ts-ignore
      flickity.current.flkty.previous()
    }
  }

  return (
    <>
      <span className="category-box__flickity--next" onClick={onFlickityNextClick}>
        <SVG src="/svgs/arrow-next.svg" />
      </span>
      <span className="category-box__flickity--prev" onClick={onFlickityPrevClick}>
        <SVG src="/svgs/arrow-previous.svg" />
      </span>
      <Flickity
        // @ts-ignore
        ref={flickity}
        className={'category-box__flickity'}
        elementType={'div'}
        options={flickityOptions}
        disableImagesLoaded={false}
        reloadOnUpdate={false}
        static={false}>
        {items}
      </Flickity>
    </>
  )
}
export default FlickityLayout
