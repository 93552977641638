import React from 'react'

const Overlay: React.FC<{ resetModal: () => void }> = ({ resetModal }) => {
  function clickBackground() {
    resetModal()
  }

  return <div className="overlay" onClick={clickBackground} />
}
export default Overlay
